<template>
  <div v-if="loading" class="lg:tw-w-8/12 tw-mt-28 applyFlexCenterWithHeight">
    <v-progress-circular
        indeterminate
        color="#008EEF"
    />
  </div>
  <div v-else-if="is_submitted" class="lg:tw-w-8/12 tw-flex tw-my-8 tw-justify-center ">
    <span>Applicant is yet to submit a response</span>
  </div>
  <div v-else class="lg:tw-w-7/12 tw-ml-5 tw-my-6 ">
    <small >{{ get_questionnaire_responses.length + ' Questions' }}</small>
    <div class="tw-mt-3" v-for="(response,index) in get_questionnaire_responses" :key="response.question_id">
      <ResponseCard
          :question="response"
          :question_index="++index"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ResponseCard from "@/components/candidateProfile/candidatePageRoute/ResponseCard";
import {getQuestionnaireResponse} from "@/services/api/APIService";

export default {
  name: "QuestionnaireResponse",
  components: {ResponseCard},
  data(){
    return{
      is_submitted:false,
      loading:false
    }
  },
  computed:{
    ...mapGetters(['get_questionnaire_responses'])
  },
  async created() {
    this.loading = true
    await getQuestionnaireResponse(this.$route.query.program_id,this.$route.query.email,this.$route.query.site_name).then(res=>{
      if (res.data.detail){
        this.is_submitted = true
      } else {
        res.data.map(q=>{
          if (Object.prototype.toString.call(q.response) === '[object String]' && q.question_type !== 'paragraph')q.response=[q.response]
        })
        this.$store.commit('set_questionnaire_response',res.data)
      }
    }).catch(e=>console.log(e)).finally(()=>this.loading=false)
  }
}
</script>

<style scoped>
small{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #1E323F;

}
</style>

<template>
  <div>
    <small class="">{{'Question ' + question_index}}</small>
    <v-card v-if="question.question_type === 'paragraph'" flat class="paragraphCard tw-mt-2 tw-mb-5">
      <h2 v-html="question.content"></h2>
      <div>
        <span>  Answer</span>
        <div class="answerCard tw-p-2">
          <p v-html="question.response"></p>
        </div>
      </div>

    </v-card>
    <v-card v-else flat class="tw-mt-2 tw-mb-5 otherCard">
      <h2 v-html="question.content"></h2>
      <div class="scroll">
        <v-radio-group
            v-if="question.question_type === 'true_false'"
            v-model="question.response[0]"
            readonly
        >
          <v-radio
              label="True"
              value="true"
              :ripple="false"
              color="#8F47F4"
          ></v-radio>
          <v-radio
              label="False"
              color="#8F47F4"
              :ripple="false"
              value="false"
          ></v-radio>
        </v-radio-group>

        <div v-else>
          <div v-if="!question.response.length">
            <div class="tw-flex tw-items-center tw-pt-2">
              <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" fill="#8F47F4" stroke="#8F47F4" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9618 8.23431C17.2742 8.54673 17.2742 9.05327 16.9618 9.36569L10.5618 15.7657C10.2494 16.0781 9.74283 16.0781 9.43041 15.7657L6.23041 12.5657C5.91799 12.2533 5.91799 11.7467 6.23041 11.4343C6.54283 11.1219 7.04936 11.1219 7.36178 11.4343L9.99609 14.0686L15.8304 8.23431C16.1428 7.9219 16.6494 7.9219 16.9618 8.23431Z" fill="white"/>
              </svg>

              <h3 class="tw-pl-2">{{option}}</h3>
            </div>
          </div>
          <div v-else v-for="option in question.response" :key="option">
            <div class="tw-flex tw-items-center tw-pt-2">
              <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="3.25" fill="#8F47F4" stroke="#8F47F4" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9618 8.23431C17.2742 8.54673 17.2742 9.05327 16.9618 9.36569L10.5618 15.7657C10.2494 16.0781 9.74283 16.0781 9.43041 15.7657L6.23041 12.5657C5.91799 12.2533 5.91799 11.7467 6.23041 11.4343C6.54283 11.1219 7.04936 11.1219 7.36178 11.4343L9.99609 14.0686L15.8304 8.23431C16.1428 7.9219 16.6494 7.9219 16.9618 8.23431Z" fill="white"/>
              </svg>

              <h3 class="tw-pl-2">{{option}}</h3>
            </div>
          </div>

        </div>
      </div>

    </v-card>
  </div>
</template>

<script>
export default {
  name: "ResponseCard",
  props:{
    question:[Object],
    question_index:[Number]
  },
  methods:{
    isCorrect(res){
      return this.question.response.some(a=> a === res)
    }
  }
}
</script>

<style scoped lang="scss">
.paragraphCard{
  padding: 11px 19px;
  width: 690px;
  background: #FBFBFB;
  border-radius: 10px;
}
.otherCard{
  padding: 11px 19px;
  width: 690px;
  background: #FBFBFB;
  border: 0.898396px solid #9CABB5;
  border-radius: 10px;
}
h2{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #1E323F;
}
small{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #475661;
}
.answerCard{
  //width: 652px;
  min-height: 184px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  border-radius: 8px;
  small{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #1E323F;
  }
}
span{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: #9CABB5;
}
.scroll {
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}
</style>
